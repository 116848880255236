@import '../../../variables/index';

.install-app-ads {
  width: 100%;
  border-radius: 15px;
  padding: 25px;
  background: $violet-color;
  margin: 0 auto;
  display: flex;
  margin-bottom: 15px;
  justify-content: space-around;
  margin-top: 10px;

  &__without {
    border: none;
  }

  &__main {
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 34px;
    color: $white-color;
    margin-right: 10px;
    display: flex;
    align-self: center;
  }

  &__ads-icon {
    display: flex;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;

    &-div {
      display: flex;
      margin-left: 20px;
      align-self: center;
      border-radius: 10px;

      &-img {
        height: 40px;
        // width: 155px;
        align-self: center;
        margin: 0 auto;
      }
    }
  }
}

.slideshow {
  width: $width;
  height: 120px;
  position: relative;
  overflow: hidden;
  margin: 0 0 15px 0;
  border-radius: 10px;
}
.slideshow-item {
  width: 100%;
  max-height: 120px;
  position: absolute;
  opacity: 0;
  animation: slideanim 27s infinite;
  visibility: hidden;
  img {
    width: auto;
    height: 100%;
  }
}

.banner-wrap768 .slideshow-item {
  animation: slideanim2 27s infinite;
}

.slideshow-item:nth-child(1),
.slideshow-item:nth-child(1) img {
  animation-delay: 0s;
}
.slideshow-item:nth-child(2),
.slideshow-item:nth-child(2) img {
  animation-delay: 9s;
}
.slideshow-item:nth-child(3),
.slideshow-item:nth-child(3) img {
  animation-delay: 18s;
}
// .slideshow-item:nth-child(4),
// .slideshow-item:nth-child(4) img {
//   animation-delay: 27s;
// }

@keyframes slideanim {
  12.5% {
    opacity: 1;
    visibility: visible;
  }
  25% {
    opacity: 1;
    visibility: visible;
  }
  37.5% {
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes slideanim2 {
  12.5% {
    opacity: 1;
    visibility: visible;
  }
  25% {
    opacity: 1;
    visibility: visible;
  }
  37.5% {
    opacity: 0;
    visibility: hidden;
  }
}
// для 3 баннеров
// .banner-wrap768 .slideshow-item {
//   animation: slideanim2 45s infinite;
// }
// .slideshow-item:nth-child(1),
// .slideshow-item:nth-child(1) img {
//   animation-delay: 0s;
// }
// .slideshow-item:nth-child(2),
// .slideshow-item:nth-child(2) img {
//   animation-delay: 12s;
// }
// .slideshow-item:nth-child(3),
// .slideshow-item:nth-child(3) img {
//   animation-delay: 24s;
// }
// @keyframes slideanim {
//   16.67% { 
//     opacity: 1;
//     visibility: visible;
//   }
//   33.33% { 
//     opacity: 1;
//     visibility: visible;
//   }
//   50% { 
//     opacity: 0;
//     visibility: hidden;
//   }
// }

// @keyframes slideanim2 {
//   16.67% { 
//     opacity: 1;
//     visibility: visible;
//   }
//   33.33% { 
//     opacity: 1;
//     visibility: visible;
//   }
//   50% {
//     opacity: 0;
//     visibility: hidden;
//   }
// }

@keyframes zoom {
  50% {
    transform: scale(1.3);
  }
}

@media only screen and (max-width: 796px) {
  .install-app-ads__main {
    font-size: 22px;
    line-height: 29px;
  }
}

@media only screen and (max-width: 680px) {
  .install-app-ads {
    display: none;
  }
  .home-page__header {
    margin-top: 0;
  }
}

.banner-wrap768 {
  display: none;
}

.banner-wrap {
  display: flex;
  max-width: $width;
  padding: 15px 20px 20px 20px;
  flex-grow: 1;
  margin: 0 auto;
  width: 100%;
  height: auto;
}

@media (max-width: 720px) {
  .banner-wrap {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (max-width: 600px) {
  .banner-wrap768 {
    padding: 15px 10px 10px;
  }
}

@media (max-width: 1000px) {
  .slideshow {
    width: 100%;
    height: 100px;
  }

  .slideshow-item img {
    width: 100%;
    height: 100px;
  }
}

@media (max-width: 900px) {
  .slideshow {
    width: 100%;
    height: 90px;
    position: relative;
    overflow: hidden;
    margin: 0 0 15px 0;
    border-radius: 10px;
  }

  .slideshow-item img {
    width: 100%;
    height: 90px;
    cursor: pointer;
    border-radius: 10px;
    object-fit: unset;
  }
}

@media (max-width: 850px) {
  .slideshow {
    width: 100%;
    height: 85px;
  }

  .slideshow-item img {
    width: 100%;
    height: 85px;
  }
}

@media (max-width: 768px) {
  .slideshow {
    width: 100%;
    height: 76px;
  }

  .slideshow-item img {
    width: 100%;
    height: 76px;
  }
}

@media (max-width: 720px) {
  .slideshow {
    width: 100%;
    height: 69px;
  }

  .slideshow-item img {
    width: 100%;
    height: 69px;
  }
}

@media (max-width: 600px) {
  .banner-wrap {
    display: none;
  }
  .banner-wrap768 {
    display: flex;
    width: 100%;
    margin-bottom: 10px;
  }

  .slideshow-item img {
    width: 100%;
    height: 170px;

    cursor: pointer;
    border-radius: 10px;
    object-fit: unset;
  }

  .slideshow {
    width: 100%;
    height: 165px;
    position: relative;
    overflow: hidden;
    margin: 0 0 15px 0;
    border-radius: 10px;
  }

}
